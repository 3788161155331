import React, { PropsWithChildren, useState } from "react";
import { StyleProp, TouchableOpacity, ViewStyle } from "react-native";
import ModalView from "./ModalView";

export default function ModalButton(
  props: PropsWithChildren<{
    style?: StyleProp<ViewStyle>;
    title?: string;
    Modal: React.FC<{ setVisible: (b: boolean) => void }>;
    onSubmit?: () => void;
    submitText?: string;
    onCancel?: () => void;
    cancelText?: string;
  }>
) {
  const { style, title, Modal, onSubmit, submitText, onCancel, cancelText, children } = props;
  const [visible, setVisible] = useState(false);
  return (
    <>
      <TouchableOpacity style={style} onPress={() => setVisible(!visible)}>
        {children}
      </TouchableOpacity>
      <ModalView
        title={title}
        onSubmit={onSubmit}
        submitText={submitText}
        onCancel={onCancel}
        cancelText={cancelText}
        visible={visible}
        setVisible={setVisible}
      >
        <Modal setVisible={setVisible} />
      </ModalView>
    </>
  );
}
