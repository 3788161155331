import { observer } from "mobx-react-lite";
import { VersionState } from "../../../model/version/VersionState";
import { useColors } from "../../Colors";
import StatusRings from "../../screens/files/StatusRings";

const VersionStateRings = observer((props: { state: VersionState }) => {
  const { state } = props;
  const colors = useColors().versionState;
  return (
    <StatusRings
      rings={[
        { value: state.fileUploadFrac ?? 0.5, color: colors.fileUpload },
        { value: state.transcodeDownloadFrac ?? 0.5, color: colors.jobDownload },
        { value: state.transcodeFrac ?? 0.5, color: colors.jobTranscode },
        { value: state.transcodeUploadFrac ?? 0.5, color: colors.jobUpload },
      ]}
    />
  );
});

export default VersionStateRings;
