import { Text, TouchableHighlight } from "react-native";
import Checkbox from "./Checkbox";

export default function ToggleListItem(props: {
  title: string;
  tooltip: string;
  value: boolean;
  setValue: (value: boolean) => void;
  colorChecked: string;
  colorUnchecked: string;
  colorText: string;
}) {
  const { title, tooltip, value, setValue, colorChecked, colorUnchecked, colorText } = props;

  return (
    <TouchableHighlight style={{ flexDirection: "row", alignItems: "center" }}>
      <>
        <Checkbox
          value={value}
          setValue={setValue}
          colorChecked={colorChecked}
          colorUnchecked={colorUnchecked}
          size={18}
        />
        <Text style={{ color: colorText }}>{title}</Text>
      </>
    </TouchableHighlight>
  );
}
