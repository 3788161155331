import { S3Client, S3ClientConfig } from "@aws-sdk/client-s3";
import config from "../config";
import { timeout } from "./util/timeout";

export type S3EndpointInfo = {
  endpoint: string;
  config: S3ClientConfig;
  lastError: number;
};

export type S3ClientInfo = {
  info: S3EndpointInfo;
  client: S3Client;
};

const s3Endpoints: S3EndpointInfo[] = config.s3.client.map((cfg) => ({
  endpoint: cfg.endpoint,
  config: cfg,
  lastError: 0,
}));

/** maps endpoints to `S3Client`s */
const s3Clients = new Map(s3Endpoints.map((v) => [v.endpoint, new S3Client(v.config)]));

/// maps paths to 404 errors
const errorsNotFound: Map<string, { endpoint: S3EndpointInfo; timestamp: number }> = new Map();

async function pickS3Endpoint(): Promise<S3EndpointInfo> {
  const info = s3Endpoints.sort((a, b) => a.lastError - b.lastError)[0];
  // delay
  await timeout(Math.max(0, config.s3.retryDelay + info.lastError - new Date().valueOf()));
  return info;
}

export async function pickS3Client(): Promise<S3ClientInfo> {
  const info = await pickS3Endpoint();
  let client = s3Clients.get(info.endpoint);
  if (!client) {
    client = new S3Client(info.config);
    s3Clients.set(info.endpoint, client);
  }
  return { info, client };
}

/**
 * request a file from S3
 * @param path must start with `/`
 * @returns
 */
export async function getS3(
  path: string,
  headers?: HeadersInit
): Promise<{ fetch: Response; endpoint: S3EndpointInfo }> {
  while (true) {
    const s3 = await pickS3Endpoint();
    try {
      const r = await fetch(s3.endpoint + path, { keepalive: true, headers });
      if (r.ok) return { fetch: r, endpoint: s3 };
      else if (r.status === 404) {
        errorsNotFound.set(path, { endpoint: s3, timestamp: new Date().valueOf() });
        await timeout(config.s3.retryDelay);
      } else s3.lastError = new Date().valueOf();
    } catch (e) {
      console.error("failed to get S3 file", e);
      s3.lastError = new Date().valueOf();
    }
  }
}
