export function register() {
  if (!("serviceWorker" in navigator)) {
    const msg =
      "Your browser does not support viewing or downloading files. Note: If you are using Firefox, please disable Private Browsing Mode or use Safari or Chrome instead";
    console.error(msg);
    alert(msg);
    return;
  }

  const swUrl = "sw.js";

  registerValidSW(swUrl);
}

function onStateChange(this: ServiceWorker) {
  console.log("Service Worker register: onStateChange", this.state);
  if (this.state === "installed") {
    if (navigator.serviceWorker.controller) {
      // At this point, the updated precached content has been fetched,
      // but the previous service worker will still serve the older
      // content until all client tabs are closed.
      console.log("Service Worker register: update available");
    } else {
      console.log("Service Worker register: Content is cached for offline use.");
    }
  }
}

function registerValidSW(swUrl: string) {
  console.log("Service Worker register: registering", swUrl);
  navigator.serviceWorker.register(swUrl).then(
    (registration) => {
      console.log("Service Worker register: registered");
      registration.addEventListener("updatefound", () => {
        const installingWorker = registration.installing;
        if (installingWorker === null) return;
        installingWorker.onstatechange = onStateChange;
      });
    },
    (error) => {
      console.error("Service Worker register: Error during service worker registration:", error);
    }
  );
}
