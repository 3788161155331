import { useLocalization } from "@fluent/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { KeyboardAvoidingView, View } from "react-native";
import { StoreContext } from "../../../model/Store";
import { VFile } from "../../../model/file/VFile";
import { useFile } from "../../../model/file/useFile";
import { useShareFromUrl } from "../../../model/hooks/useShareFromUrl";
import VideoStore, { VideoStoreContext } from "../../../model/ui/VideoStore";
import { uuidFromBase64 } from "../../../model/util/CryptoHelper";
import { useWindowDimensions } from "../../../model/util/useWindowDimensions";
import { useVersion } from "../../../model/version/useVersion";
import styles from "../../Styles";
import ImportShareModal from "../../modals/ImportShareModal";
import AuthModal, { AuthModalContext } from "../../modals/auth/AuthModal";
import { useLocation, useNavigate, useParams, useSearchParams } from "../../navigation/routing";
import { useResetNavigationState } from "../../navigation/useResetNavigationState";
import ErrorScreen from "../ErrorScreen";
import LoadScreen from "../LoadScreen";
import PreviewScreenDesktop from "./PreviewScreenDesktop";
import PreviewScreenMobile from "./PreviewScreenMobile";

const PreviewScreen = observer(() => {
  const _store = useContext(StoreContext);
  const params = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const fileId = uuidFromBase64(params.fileId);
  const { share, shareKey } = useShareFromUrl();
  const { status: fileStatus, data: file, error: fileError } = useFile(fileId, { share, shareKey });
  const { status: versionStatus, data: version, error: versionError } = useVersion(file ?? undefined);
  const { l10n } = useLocalization();

  useEffect(() => {
    let versionNo = -1;
    const v = searchParams.get("v");
    if (v !== null) {
      const n = Number.parseInt(v);
      if (Number.isInteger(n)) {
        if (n === 0) searchParams.delete("v");
        else if (file && file.numberOfVersions < n) searchParams.delete("v");
        else if (file && file.numberOfVersions < -n) searchParams.set("v", "1");
        else if (n) {
          versionNo = n < 0 ? n : n - 1;
          searchParams.set("v", n.toString());
        }
      } else {
        searchParams.delete("v");
      }
      setSearchParams(searchParams);
    }
    runInAction(() => {
      if (version) version.file.selectedVersionNo = versionNo;
    });
  }, [file, version, searchParams]);

  const navigate = useNavigate();
  useEffect(() => {
    if (!(_store.me || (share && shareKey)) && (fileError !== undefined || fileId === null)) {
      navigate("/signup?" + new URLSearchParams({ redirect: location.pathname + "?" + searchParams }));
    }
  }, [share, shareKey, fileError]);

  switch (fileStatus) {
    case "error":
      console.error("Failed to load file", fileError);
      return <ErrorScreen msg={l10n.getString("file-load-failed", { error: `${fileError}` })} />;
    case "fetching":
      return <LoadScreen msg={l10n.getString("file-loading")} />;
  }
  switch (versionStatus) {
    case "error":
      return <ErrorScreen msg={l10n.getString("version-load-failed", { error: `${versionError}` })} />;
    case "fetching":
      return <LoadScreen msg={l10n.getString("version-loading")} />;
  }
  return <PreviewScreenInner file={file} />;
});

const PreviewScreenInner = observer((props: { file: VFile }) => {
  const { file } = props;
  const _vsStore = useMemo(() => new VideoStore({ file }), []);
  useEffect(() => {
    runInAction(() => (_vsStore.file = file));
  }, [file]);
  const dimensions = useWindowDimensions();

  const [authVisible, setAuthVisible] = useState(false);

  useResetNavigationState(file);

  return (
    <VideoStoreContext.Provider value={_vsStore}>
      <AuthModalContext.Provider value={{ setAuthVisible }}>
        <View style={styles.fill}>
          <KeyboardAvoidingView behavior="height" style={{ height: "100%" }}>
            {dimensions.width > 700 ? <PreviewScreenDesktop file={file} /> : <PreviewScreenMobile file={file} />}
          </KeyboardAvoidingView>
        </View>
        <AuthModal visible={authVisible} setVisible={setAuthVisible} />
        {file && <ImportShareModal file={file} />}
      </AuthModalContext.Provider>
    </VideoStoreContext.Provider>
  );
});

export default PreviewScreen;
