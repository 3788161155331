import { base64Encode, exportRawKey } from "../util/CryptoHelper";
import FileVersion from "./FileVersion";

export async function downloadFileVersion(v: FileVersion): Promise<void> {
  const path = v.getS3Path("original");
  const hash = encodeURIComponent(
    JSON.stringify({
      key: base64Encode(await exportRawKey(v.encKey)),
      size: v.size,
      path,
      filename: v.name,
    })
  );
  window.location.href = `http://localhost:8080/decrypt-file#${hash}`;
}
