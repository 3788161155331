import { useLocalization } from "@fluent/react";
import { observer } from "mobx-react-lite";
import { Text, View } from "react-native";
import { VFile } from "../../../model/file/VFile";
import FileVersion from "../../../model/version/FileVersion";
import ImageVersion from "../../../model/version/ImageVersion";
import { VersionStateEnum, versionStateMessage } from "../../../model/version/VersionState";
import VideoVersion from "../../../model/version/VideoVersion";
import { useVersion } from "../../../model/version/useVersion";
import { useColors } from "../../Colors";
import BlinkingDots from "../../components/status-rings/BlinkingDots";
import VersionStateRings from "../../components/status-rings/VersionStateRings";
import ErrorScreen from "../ErrorScreen";
import LoadScreen from "../LoadScreen";
import ImageVersionImage from "./image/ImageVersionImage";
import VideoView from "./video/VideoView";

const GalleryContent = observer((props: { file: VFile }) => {
  const { file } = props;
  const { data: version, status: versionStatus, error: versionError } = useVersion(file ?? undefined);

  const colors = useColors().previewScreen;
  const { l10n } = useLocalization();

  switch (versionStatus) {
    case "fetching":
      return <LoadScreen msg={l10n.getString("file-loading")} />;
    case "error":
      return <ErrorScreen msg={l10n.getString("version-load-failed", { error: `${versionError}` })} />;
  }

  if (version instanceof FileVersion && version.state.current !== VersionStateEnum.done)
    return (
      <View style={{ height: "100%", width: "100%", justifyContent: "center", alignItems: "center" }}>
        <View style={{ width: 200, height: 200, maxWidth: "100%", maxHeight: "100%", position: "absolute" }}>
          <VersionStateRings state={version.state} />
          <Text
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              padding: 10,
              color: colors.text,
              textAlign: "center",
              fontSize: 13,
            }}
          >
            {versionStateMessage(version.state.current)}
            <BlinkingDots />
          </Text>
        </View>
      </View>
    );

  if (version instanceof ImageVersion)
    return (
      <View style={{ height: "100%", width: "100%" }}>
        <ImageVersionImage version={version} />
      </View>
    );
  if (version instanceof VideoVersion) return <VideoView version={version} />;
  return <LoadScreen msg={l10n.getString("unsupported-file-type", { name: version.name })} />;
});

export default GalleryContent;
