import { useLocalization } from "@fluent/react";
import { View } from "react-native";
import { StoreContext, store } from "../../model/Store";
import { useMaybePromise } from "../../model/hooks/useMaybePromise";
import { useColors } from "../Colors";
import styles from "../Styles";
import LoadScreen from "../screens/LoadScreen";
import LoginScreen from "../screens/auth/LoginScreen";
import SignupScreen from "../screens/auth/SignupScreen";
import FilesScreen from "../screens/files/FilesScreen";
import NotificationsScreen from "../screens/notifications/NotificationsScreen";
import PreviewScreen from "../screens/preview/PreviewScreen";
import AccountSettingsScreen from "../screens/settings/AccountSettingsScreen";
import SettingsScreen from "../screens/settings/SettingsScreen";
import TranscodingServersSettingsScreen from "../screens/settings/TranscodingServersSettingsScreen";
import { Route, Routes } from "./routing";

export default function AppRouter() {
  let _store = useMaybePromise(store);
  const colors = useColors();
  const { l10n } = useLocalization();

  return _store === undefined ? (
    <LoadScreen msg={l10n.getString("store-loading")} />
  ) : (
    <StoreContext.Provider value={_store}>
      <View style={[styles.fill, { backgroundColor: colors.background }]}>
        <Routes>
          <Route path="/" element={<FilesScreen />} />
          <Route path="preview">
            <Route path=":fileId" element={<PreviewScreen />} />
          </Route>
          <Route path="settings">
            <Route path="" element={<SettingsScreen />} />
            <Route path="account" element={<AccountSettingsScreen />} />
            <Route path="transcoding-servers" element={<TranscodingServersSettingsScreen />} />
            <Route path="add-account">
              <Route path="login" element={<LoginScreen navbar />} />
              <Route path="signup" element={<SignupScreen navbar />} />
            </Route>
          </Route>
          <Route path="notifications" element={<NotificationsScreen />} />
          <Route path="login" element={<LoginScreen />} />
          <Route path="signup" element={<SignupScreen />} />
        </Routes>
      </View>
    </StoreContext.Provider>
  );
}
