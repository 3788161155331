import { runInAction } from "mobx";
import * as Api from "../Api";
import { store } from "../Store";
import { getUser } from "../user/getUser";
import {
  base64Encode,
  decryptCiphertext,
  exportRawKey,
  getRelation,
  importAesEncryptedAesKey,
} from "../util/CryptoHelper";
import DirectoryVersion from "../version/DirectoryVersion";
import { getVersion } from "../version/getVersion";
import Comment, {
  CommentJson,
  CommentVersion,
  CommentVersionCiphertextJson,
  commentRelData,
  isExistingCommentVersionJson,
  parseCommentType,
} from "./Comment";
import { Commentable } from "./Commentable";

const batchSize = 20;

export async function subscribeComments(c: Commentable, recurse: number) {
  if (c.maxExistingRelation + 1 >= c.nextRelation) {
    //console.debug(`subscribe next comments for ${c.id}`);
    try {
      const start = c.nextRelation;
      c.nextRelation += batchSize;
      const relations = await Promise.all(
        [...new Array(batchSize).keys()].map(async (n) => base64Encode(await getRelation(c.id, c.encKey, start + n)))
      );
      const _store = await store;
      for (const [i, r] of relations.entries()) {
        _store.subscribedCommentRelations.set(r, { c, n: start + i });
      }
      const data = relations.map((r) => `{relation: "${r}", ciphertext: ""}`).join(",");

      // prettier-ignore
      const r: CommentJson[] = (await Api.gql(`mutation {
        subscribeComments(data: [${data}], ${_store.me ? "": `subscriptionId: "${_store.subscriberId}"` }) {
          id
          type
          relation
          key
          versions {
            author
            ciphertext
            createdAt
            deletedAt
          }
          deletedAt
        }
      }`, `Bearer ${_store.me?.token.t}`)).subscribeComments;

      for (const cd of r) {
        const { relationNo, version, replyTo } = commentRelData(_store, cd.relation);

        let comment = c.comments.get(cd.id);
        const encKey = await importAesEncryptedAesKey(c.encKey, cd.key);
        const type = parseCommentType(cd.type);

        if (comment) {
          comment.encKey = encKey;
        } else {
          comment = new Comment({
            id: cd.id,
            type,
            encKey,
            relationNo,
            version,
            replyTo,
            versions: new Map(),
            deletedAt: cd.deletedAt ? new Date(cd.deletedAt) : undefined,
          });
          _store.comments.set(cd.id, comment);
        }

        const versions = await Promise.all(
          cd.versions.map<Promise<CommentVersion>>(async (vd, i) => {
            try {
              const author = await getUser({ id: vd.author });
              const createdAt = new Date(vd.createdAt);
              if (isExistingCommentVersionJson(vd)) {
                const { text, startTimecode, endTimecode } = await decryptCiphertext<CommentVersionCiphertextJson>(
                  encKey,
                  vd.ciphertext
                );
                return { author, createdAt, text, startTimecode, endTimecode };
              } else {
                return { author, createdAt, deletedAt: new Date(vd.deletedAt) };
              }
            } catch (e) {
              throw new Error("Failed to parse comment version", {
                cause: { vd, encKey: base64Encode(await exportRawKey(encKey)), e },
              });
            }
          })
        );
        runInAction(() => {
          for (const [i, cv] of versions.entries()) {
            comment!.versions.set(i, cv);
          }
          c.comments.set(cd.id, comment!);
        });
      }
    } catch (e) {
      c.nextRelation -= batchSize;
      throw e;
    }
  }
  const _store = await store;
  if (c.maxExistingRelation < c.nextRelation && recurse > 0 && c instanceof DirectoryVersion) {
    const files = c.childrenIds.map((id) => _store.files.get(id));
    for (const file of files) {
      if (!file) continue;
      try {
        const v = await getVersion({ id: file.versionIds.at(file.selectedVersionNo)!, file });
        if (v.maxExistingRelation + 1 >= v.nextRelation) subscribeComments(v, 0);
      } catch (e) {
        console.warn("Error while subscribing to child version:", file, e);
      }
    }
  }
}
