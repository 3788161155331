import React, { ReactNode, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { StyleProp, Text, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native";
import { useColors } from "../Colors";

export default function ModalView(props: {
  title?: string;
  titleStyle?: StyleProp<TextStyle>;
  onSubmit?: () => void;
  submitText?: string;
  onCancel?: () => void;
  cancelText?: string;
  visible: boolean;
  setVisible: (b: boolean) => void;
  noButtons?: boolean;
  children: ReactNode;
}): JSX.Element {
  const { title, titleStyle, onSubmit, submitText, onCancel, cancelText, visible, setVisible, noButtons, children } =
    props;
  const colors = useColors().modal;
  const textStyle: TextStyle = { color: colors.text, fontSize: 16, fontWeight: "500" };
  const buttonStyle: ViewStyle = { borderRadius: 8, padding: 6 };

  const ref = useRef(null);
  const el = useRef(document.createElement("div"));
  el.current.style.zIndex = "1";
  useEffect(() => {
    const portal = document.getElementById("root")!;
    if (visible) portal.appendChild(el.current);
    else
      try {
        portal.removeChild(el.current);
      } catch (e) {}

    return () => {
      try {
        portal.removeChild(el.current);
      } catch (e) {}
    };
  }, [visible]);

  return createPortal(
    <div
      ref={ref}
      onClick={(e) => {
        if (ref.current !== e.target) return;
        setVisible(!visible);
        onCancel?.();
      }}
      style={{
        position: "absolute",
        display: visible ? "flex" : undefined,
        flexDirection: "column",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        margin: 0,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {visible && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "90%",
            margin: 40,
            padding: 10,
            borderRadius: 6,
            backgroundColor: colors.background,
            boxShadow: `${colors.shadow} 0 0 20px`,
          }}
        >
          {title && (
            <Text style={[{ color: colors.text, fontWeight: "600", fontSize: 24, paddingBottom: 8 }, titleStyle]}>
              {title}
            </Text>
          )}
          {children}
          {!noButtons && (
            <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
              <TouchableOpacity
                style={buttonStyle}
                onPress={() => {
                  setVisible(false);
                  onCancel?.();
                }}
              >
                <Text style={textStyle}>{cancelText ?? "Cancel"}</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={buttonStyle}
                onPress={() => {
                  setVisible(false);
                  onSubmit?.();
                }}
              >
                <Text style={textStyle}>{submitText ?? "Submit"}</Text>
              </TouchableOpacity>
            </View>
          )}
        </div>
      )}
    </div>,
    el.current
  ) as JSX.Element;
}
