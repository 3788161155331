import { Localized, useLocalization } from "@fluent/react";
import { runInAction } from "mobx";
import { observer } from "mobx-react-lite";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { StoreContext } from "../../../model/Store";
import Comment, { WipComment } from "../../../model/comment/Comment";
import { createComment } from "../../../model/comment/createComment";
import { deleteComment } from "../../../model/comment/deleteComment";
import { editComment } from "../../../model/comment/editComment";
import { subscribeComments } from "../../../model/comment/subscribeComments";
import VideoStore, { VideoStoreContext } from "../../../model/ui/VideoStore";
import { useColors } from "../../Colors";
import ModalButton from "../../modals/ModalButton";
import UserModal from "../../modals/user/UserModal";
import TimecodeText from "../../screens/preview/video/TimecodeText";
import LoadCircle from "../../util/LoadCircle";
import ContextMenu from "../context-menu/ContextMenu";
import Icon from "../icon/Icon";
import UserCircle from "../user/UserCircle";
import UserNameText from "../user/UserNameText";
import CommentView from "./CommentView";
import CommentViewText from "./CommentViewText";
import MultilineTextInput from "./MultilineTextInput";
import NewCommentTimecodeButton from "./NewCommentTimecodeButton";

const CommentViewInner = observer((props: { comment: Comment; isHighlighted: boolean }) => {
  const { comment, isHighlighted } = props;
  const _store = useContext(StoreContext);
  const colors = useColors().comment;
  const { l10n } = useLocalization();
  const toggleColors = useColors().commentContextToggle;
  const vStore = useContext(VideoStoreContext) as VideoStore | undefined;

  const [wipComment, setWipComment] = useState<WipComment | null>(null);
  const [wipType, setWipType] = useState<"edit" | "reply" | null>(null);
  const [isSending, setIsSending] = useState(false);

  const reset = useCallback(() => {
    runInAction(() => (_store.commentReplyTo = null));
    setWipComment(null);
    setWipType(null);
    setIsSending(false);
  }, [setWipComment, setWipType, setIsSending, _store]);

  const gotoTimeline = useCallback(() => {
    if (comment.latestVersion?.startTimecode !== undefined) vStore?.setPosition(comment.latestVersion.startTimecode);
  }, [comment, vStore]);

  const startEditing = useCallback(() => {
    setWipComment(
      new WipComment({
        text: comment.latestVersion?.text,
        type: "io.vidre.text",
        startTimecode:
          comment.latestVersion?.endTimecode !== undefined ? comment.latestVersion?.startTimecode : undefined,
        endTimecode: comment.latestVersion?.endTimecode,
      })
    );
    setWipType("edit");
    gotoTimeline();
  }, [setWipType, setWipComment, comment, reset, gotoTimeline]);
  const sendEdit = useCallback(async () => {
    if (!isSending && wipComment) {
      setIsSending(true);
      await editComment({ comment, wip: wipComment, currentTimecode: vStore?.positionMillis });
      reset();
    }
  }, [isSending, wipComment, setIsSending, comment, reset]);

  const onReply = useCallback(() => {
    runInAction(() => (_store.commentReplyTo = comment));
    setWipComment(new WipComment({ type: "io.vidre.text" }));
    setWipType("reply");
    gotoTimeline();
  }, [_store, comment, setWipComment, setWipType, gotoTimeline]);
  const sendReply = useCallback(async () => {
    if (!isSending && wipComment) {
      setIsSending(true);
      await createComment({
        commentContext: comment,
        wipComment,
        currentTimecode: vStore?.positionMillis,
      });
      reset();
    }
  }, [isSending, wipComment, setIsSending, comment, reset]);

  useEffect(() => {
    if (_store.commentReplyTo !== comment && wipType === "reply") {
      setWipComment(null);
      setWipType(null);
    }
  }, [_store.commentReplyTo]);

  const replys: Comment[] = [...comment.comments.values()];

  useEffect(() => {
    subscribeComments(comment, 0).catch((e) => console.error("Error in subscribeComments", e));
  }, [comment]);

  const toggleIncludeTimecode = useCallback(() => {
    runInAction(() => {
      _store.commentReplyLinkTimecode = !_store.commentReplyLinkTimecode;
    });
  }, [_store]);

  const timecodeColor =
    (_store.commentReplyLinkTimecode && vStore?.status?.isLoaded) || typeof wipComment?.startTimecode === "number"
      ? toggleColors.accent
      : toggleColors.disabled;

  return (
    <ContextMenu
      actions={[
        { title: l10n.getString("comment-reply"), icon: "reply", onPress: startEditing },
        { title: l10n.getString("comment-edit"), icon: "edit", disabled: !comment.canEdit, onPress: startEditing },
        {
          title: l10n.getString("comment-delete"),
          icon: "delete",
          disabled: !comment.canDelete,
          onPress: () => deleteComment(comment),
        },
      ]}
    >
      <View
        style={{
          padding: 10,
          backgroundColor: isHighlighted ? colors.highlighted : colors.background,
          borderRadius: 15,
        }}
      >
        <View style={{ flexDirection: "row", alignItems: "center", paddingBottom: 8 }}>
          {/* author */}
          <ModalButton Modal={() => <UserModal user={comment.oldestVersion.author} />}>
            <View style={{ flexDirection: "row", alignItems: "center" }}>
              <UserCircle user={comment.oldestVersion.author} size={20} />
              <UserNameText user={comment.oldestVersion.author} style={{ paddingLeft: 8 }} />
            </View>
          </ModalButton>

          {/* timecode */}
          {comment.latestVersion?.startTimecode !== undefined && (
            <>
              <View style={{ flexGrow: 1 }} />
              <View style={{ padding: 4, borderRadius: 4, backgroundColor: colors.timecode.background }}>
                <Text style={{ color: colors.text, fontWeight: "600", fontSize: 13 }}>
                  <TimecodeText millis={comment.latestVersion.startTimecode} fps={vStore?.fps} color={colors.text} />
                  {comment.latestVersion?.endTimecode !== undefined && (
                    <>
                      <Text>{" - "}</Text>
                      <TimecodeText millis={comment.latestVersion.endTimecode} fps={vStore?.fps} color={colors.text} />
                    </>
                  )}
                </Text>
              </View>
            </>
          )}
        </View>

        {/* text */}
        {wipType === "edit" && wipComment ? (
          <View style={{ borderRadius: 4, backgroundColor: colors.editBackground, marginHorizontal: -4, padding: 4 }}>
            <MultilineTextInput
              autoFocus
              color={colors.text}
              text={wipComment.text}
              onChangeText={(t) => runInAction(() => (wipComment.text = t))}
              placeholder={comment.latestVersion?.text ?? l10n.getString("comment-edit")}
              onSubmitEditing={sendEdit}
            />
          </View>
        ) : (
          <CommentViewText comment={comment} />
        )}

        {_store.commentReplyTo === comment && wipComment && (
          <View style={{ borderRadius: 4, backgroundColor: colors.editBackground, marginHorizontal: -4, padding: 4 }}>
            <Localized id="comment-reply" attrs={{ placeholder: true }}>
              <MultilineTextInput
                autoFocus
                color={colors.text}
                text={wipComment.text}
                onChangeText={(t) => runInAction(() => (wipComment.text = t))}
                onSubmitEditing={sendReply}
              />
            </Localized>
          </View>
        )}
        {/* left */}
        <View style={{ flexDirection: "row", paddingVertical: 4 }}>
          {wipType === null ? (
            <>
              {/* reply */}
              <TouchableOpacity onPress={onReply}>
                <Text style={{ color: colors.icon, fontWeight: "600", fontSize: 13 }}>Reply</Text>
              </TouchableOpacity>
            </>
          ) : (
            <TouchableOpacity onPress={toggleIncludeTimecode} style={{ marginLeft: -4 }}>
              <NewCommentTimecodeButton wipComment={wipComment} color={timecodeColor} />
            </TouchableOpacity>
          )}
          {/* spacer */}
          <View style={{ flexGrow: 1 }} />
          {/* right */}
          {isSending ? (
            <LoadCircle size={20} color={colors.icon} />
          ) : wipType === null ? (
            <>
              {/* edit */}
              {comment.canEdit && (
                <TouchableOpacity onPress={startEditing}>
                  <Icon name="edit" size={20} color={colors.icon} />
                </TouchableOpacity>
              )}
            </>
          ) : (
            <>
              {/* reset */}
              <TouchableOpacity onPress={reset}>
                <Icon name="close" size={20} color={colors.icon} />
              </TouchableOpacity>
              {/* send */}
              <TouchableOpacity onPress={wipType === "edit" ? sendEdit : sendReply}>
                <Icon name="check" size={20} color={colors.icon} />
              </TouchableOpacity>
            </>
          )}
        </View>
      </View>

      {/* replys */}
      {comment.commentsVisible &&
        replys.map((c, i) => (
          <View style={{ flexDirection: "row", width: "100%" }} key={`${c.id}${i}`}>
            <View
              style={{
                marginTop: 8,
                width: 2,
                backgroundColor: colors.replyBar,
              }}
            />
            <CommentView comment={c} style={{ paddingTop: 8 }} />
          </View>
        ))}
    </ContextMenu>
  );
});

export default CommentViewInner;
