import { makeObservable, observable } from "mobx";
import { createRef } from "react";
import { SectionList } from "react-native";
import * as Api from "../Api";
import { store, unsafeStore } from "../Store";
import { CacheableC } from "../cacheable/Cacheable";
import Comment, { WipComment } from "../comment/Comment";
import { Commentable } from "../comment/Commentable";
import { sortComments } from "../comment/sortComments";
import { VFile } from "../file/VFile";
import { aesDecrypt, aesEncrypt, base64Decode, base64Encode, importRawKey } from "../util/CryptoHelper";
import { VersionState, VersionStateFromServer, VersionStateJson } from "./VersionState";

export default abstract class Version extends CacheableC implements Commentable {
  readonly id: string;
  file: VFile;
  name: string;
  encKey: CryptoKey;
  readonly createdAt: Date;

  get ciphertext(): VersionCiphertextJson {
    return { name: this.name };
  }

  nextRelation = 0;
  get maxExistingRelation() {
    return this.comments.size > 0
      ? [...this.comments.values()].map((v) => v.relationNo).reduce((a, b) => Math.max(a, b))
      : -1;
  }

  wipComment: WipComment | null = null;
  setComment(t: string): WipComment {
    if (this.wipComment) this.wipComment.text = t;
    else this.wipComment = new WipComment({ text: t, type: "io.vidre.text" });
    return this.wipComment;
  }
  comments = observable.map<string, Comment>();

  commentsVisible = true;

  get sortedComments(): Comment[] {
    return sortComments([...this.comments.values()], unsafeStore.commentSortStrategy);
  }

  commentsListRef = createRef<SectionList<Comment>>();

  /** **Note**: must be added to `store.versions` */
  constructor(params: { id: string; file: VFile; name: string; encKey: CryptoKey; createdAt: Date }) {
    const { id, file, name, encKey, createdAt } = params;
    super();
    this.id = id;
    this.file = file;
    this.name = name;
    this.encKey = encKey;
    this.createdAt = createdAt;
    makeObservable(this, {
      file: true,
      name: true,
      encKey: true,
      wipComment: true,
      setComment: true,
      commentsVisible: true,
      sortedComments: true,
      rename: true,
    });
  }

  async download() {
    alert("Downloading this file type is not yet supported");
  }

  async rename(name: string) {
    if (name === this.name) return;
    const ciphertext = this.ciphertext;
    ciphertext.name = name;
    const encrypted = await aesEncrypt(
      this.encKey,
      Buffer.from(JSON.stringify(ciphertext), "utf8"),
      new ArrayBuffer(16)
    );
    await Api.gql(
      `mutation {
            updateVersionCiphertext(
                id: "${this.id}",
                ciphertext: "${base64Encode(encrypted)}"
            )
        }`,
      `Bearer ${(await store).me?.token.t}`
    );
    this.name = name;
  }
}

export const versionParamsFromJson = async (json: VersionJson, params: { file: VFile }): Promise<VersionParams> => {
  const encKey = await importRawKey(await aesDecrypt(params.file.encKey, base64Decode(json.key), new ArrayBuffer(16)));
  const createdAt = new Date(json.createdAt);
  const ciphertext = JSON.parse(
    Buffer.from(await aesDecrypt(encKey, base64Decode(json.ciphertext), new ArrayBuffer(16))).toString("utf-8")
  );
  const state = VersionStateFromServer(json.state);
  return { id: json.id, ciphertext, encKey, state, createdAt };
};

export type VersionParams = {
  id: string;
  ciphertext: VersionCiphertextJson;
  encKey: CryptoKey;
  state: VersionState;
  createdAt: Date;
};

export type VersionJson = {
  id: string;
  key: string;
  state: VersionStateJson;
  ciphertext: string;
  createdAt: string;
};

export type VersionCiphertextJson = {
  name: string;
};

export const versionGQLFields = `
    id
    size
    key
    ciphertext
    createdAt
    fileTypes
    numberOfChildren
    state {
        current
        job {
            id
            transcodingServer
        }
    }
`;

export const versionNoFromUrl = (v: number | undefined, file: VFile | undefined) =>
  v !== undefined
    ? v < 0 && v >= -(file?.numberOfVersions ?? 0)
      ? v
      : v > 0 && v <= (file?.numberOfVersions ?? 0)
      ? v + 1
      : -1
    : -1;
