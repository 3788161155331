// Array.at

const TypedArray = Reflect.getPrototypeOf(Int8Array);
for (const C of [Array, String, TypedArray]) {
  // Array.at
  if (C.prototype.at === undefined) {
    function at(n) {
      // ToInteger() abstract op
      n = Math.trunc(n) || 0;
      // Allow negative indexing from the end
      if (n < 0) n += this.length;
      // OOB access is guaranteed to return undefined
      if (n < 0 || n >= this.length) return undefined;
      // Otherwise, this is just normal property access
      return this[n];
    }
    Object.defineProperty(C.prototype, "at", {
      value: at,
      writable: true,
      enumerable: false,
      configurable: true,
    });
  }

  // Array.findLast
  if (Array.findLast === undefined) {
    function findLast() {
      return this.reverse().find(...arguments);
    }
    Object.defineProperty(C.prototype, "findLast", {
      value: findLast,
      writable: true,
      enumerable: false,
      configurable: true,
    });
  }
}

// Promise.allSettled
if (Promise.allSettled === undefined) {
  function allSettled(promises) {
    return Promise.all(
      promises.map((p) =>
        p.then((v) => ({ status: "fulfilled", value: v })).catch((e) => ({ status: "rejected", reason: e }))
      )
    );
  }
  Object.defineProperty(Promise, "allSettled", {
    value: allSettled,
    writable: true,
    enumerable: false,
    configurable: true,
  });
}
