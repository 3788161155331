import { runInAction } from "mobx";
import * as Api from "../../Api";
import { store } from "../../Store";
import { VFile, VFileDirectory } from "../../file/VFile";
import { Share } from "../../share/Share";
import BinaryVersion from "../../version/BinaryVersion";
import DirectoryVersion, { DirectoryVersionCiphertextJson } from "../../version/DirectoryVersion";
import FileVersion, { FileVersionCiphertextJson, createVersionCiphertext } from "../../version/FileVersion";
import ImageVersion from "../../version/ImageVersion";
import { VersionStateEnum, VersionStateJson } from "../../version/VersionState";
import VideoVersion from "../../version/VideoVersion";
import { CreateVersionData, CreateVersionResult, createVersions } from "../create/createVersion";
import { Dir, Fil, UploadElement } from "../types/UploadElement";
import { executeMultipartUpload } from "./uploadQueue";

/**
 * create directory tree & upload files
 * @param files Map of file name and UploadElemet
 * @param parentDir parent directory
 */
export async function uploadFileTree(files: Map<string, UploadElement>, parentDir: VFileDirectory | null) {
  console.log("upload file tree", parentDir);
  // create Versions & Files on API Server
  const r = await createVersionsApi(files, parentDir);

  // for each version created on API Server.
  const filesResultPromise = r.map(async (cvr, i) => {
    // get original UploadElement (Fil/Dir)
    const [name, uploadElement] = [...files.entries()][i];
    // shared file params
    const fileParams: SharedFileParams = {
      id: cvr.id,
      key: cvr.fileKey,
      shares: cvr.shares,
      parentDir,
      versionIds: [cvr.latestVersion.id],
      createdAt: cvr.createdAt,
    };
    // shared version params
    const versionParams: SharedVersionParams = {
      id: cvr.latestVersion.id,
      name,
      encKey: cvr.key,
      createdAt: new Date(cvr.latestVersion.createdAt),
    };
    // is a directory
    if (uploadElement instanceof Dir) return handleDir({ fileParams, versionParams, dir: uploadElement, cvr });
    // is a file
    if (uploadElement instanceof Fil) return handleFil({ fileParams, versionParams, fil: uploadElement });
  });
  const filesResult = await Promise.allSettled(filesResultPromise);
  const _store = await store;
  // handle result & insert into store
  runInAction(() => {
    for (const r of filesResult) {
      if (r.status === "rejected") {
        console.error("Error while starting upload of file to directory", r.reason);
        continue;
      }
      if (r.value === undefined) {
        console.error("Error while starting upload of file to directory: neither Fil nor Dir");
        continue;
      }
      _store.versions.set(r.value.version.id, r.value.version);
      _store.files.set(r.value.file.id, r.value.file);
    }
  });
}

/**
 * Create versions on the server
 */
async function createVersionsApi(
  files: Map<string, UploadElement>,
  parentDir: VFileDirectory | null
): Promise<CreateVersionResult[]> {
  const data: CreateVersionData<DirectoryVersionCiphertextJson | FileVersionCiphertextJson>[] = [
    ...files.entries(),
  ].map(([name, ue]) => {
    // is a dir
    if (ue instanceof Dir)
      return {
        ciphertext: { name },
        type: "DIRECTORY",
      };
    // is a file
    if (ue instanceof Fil)
      return {
        ciphertext: createVersionCiphertext(ue.file),
        type: ue.type,
        size: ue.file.size,
      };
    throw new Error(`UploadElemet is neither a Fil nor a Dir: ${name}: ${ue}`);
  });
  return await createVersions({ data, parentDir });
}

/**
 * Create local VFile & DirectoryVersion and recursively continue upload
 */
async function handleDir(params: {
  fileParams: SharedFileParams;
  versionParams: SharedVersionParams;
  dir: Dir;
  cvr: CreateVersionResult;
}): Promise<{ file: VFileDirectory; version: DirectoryVersion }> {
  const { fileParams, versionParams, dir, cvr } = params;
  // construct local VFile & DirectoryVersion
  const file = new VFile({
    type: "DIRECTORY",
    ...fileParams,
  }) as VFileDirectory;
  const version = new DirectoryVersion({
    fileTypes: new Set(cvr.latestVersion.fileTypes),
    numberOfChildren: 0,
    file,
    ...versionParams,
  });
  // recursive upload
  uploadFileTree(dir.files, file);
  return { file, version };
}

/**
 * Create local VFile & FileVersion and start upload to S3
 */
async function handleFil(params: {
  fileParams: SharedFileParams;
  versionParams: SharedVersionParams;
  fil: Fil;
}): Promise<{ file: VFile; version: FileVersion }> {
  const { fileParams, versionParams, fil } = params;
  const me = (await store).me;
  if (!me) throw new Error("Must be signed in to upload file");
  // shared file params
  const fileVersionParams = {
    originalMimeType: fil.file.type,
    size: fil.size,
    ...versionParams,
  };
  // construct local VFile
  const file = new VFile({
    type: fil.type,
    ...fileParams,
  });
  // construct local FileVersion
  let version: FileVersion;
  switch (fil.type) {
    case "IMAGE":
      version = new ImageVersion({
        file,
        state: fil,
        ...fileVersionParams,
      });
      break;
    case "VIDEO":
      version = new VideoVersion({
        file,
        // TODO
        aspectRatio: 16 / 9,
        fps: 25,
        state: fil,
        ...fileVersionParams,
      });
      break;
    case "BINARY":
      version = new BinaryVersion({
        file,
        state: fil,
        ...fileVersionParams,
      });
      break;
    case "DIRECTORY":
      throw new Error("A file cannot be a directory");
  }
  // queue upload to S3
  executeMultipartUpload({ id: version.id, fil: fil, key: versionParams.encKey }).then(() => {
    if (version instanceof VideoVersion) me.preferredTranscodingServer.startJob(version);
    else if (version instanceof FileVersion) {
      const data: VersionStateJson = {
        current: "done",
      };
      // TODO: retry
      // TODO: run on api server instead in hook from minio?
      Api.req({
        endpoint: `/version/${version.id}/state`,
        token: me.token.t,
        body: JSON.stringify(data),
      }).then((r) => {
        if (r.ok) {
          runInAction(() => {
            version.state.current = VersionStateEnum.done;
          });
        }
      });
    }
  });
  return { file, version };
}

type SharedFileParams = {
  id: string;
  key: CryptoKey;
  shares: Map<string, Share>;
  parentDir: VFileDirectory | null;
  versionIds: string[];
  createdAt: Date;
};

type SharedVersionParams = {
  id: string;
  name: string;
  encKey: CryptoKey;
  createdAt: Date;
};
