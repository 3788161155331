import * as Crypto from "expo-crypto";
import { Range } from "header-range-parser";
import { v4 as uuidv4 } from "uuid";

// hash

export async function keyhash(key: ArrayBuffer, salt: string): Promise<CryptoKey> {
  let s = Buffer.from(salt, "utf-8");
  let a = new Uint8Array(key.byteLength + s.byteLength);
  a.set(new Uint8Array(key), 0);
  a.set(s, key.byteLength);
  return importRawKey(await crypto.subtle.digest("SHA-256", a));
}

export function pwhash(password: string, salt: ArrayBuffer): Promise<ArrayBuffer> {
  let p = Buffer.from(password, "utf-8");
  let a = new Uint8Array(p.byteLength + salt.byteLength);
  a.set(p, 0);
  a.set(new Uint8Array(salt), p.byteLength);
  return crypto.subtle.digest("SHA-512", a);
}

export function shasum(s: string): Promise<ArrayBuffer> {
  return crypto.subtle.digest("SHA-512", Buffer.from(s, "utf-8"));
}

// random

export function randomBytes(length: number): Uint8Array {
  return crypto.getRandomValues(new Uint8Array(length));
}

// AES

export function aesEncrypt(key: CryptoKey, data: ArrayBuffer, iv: ArrayBuffer): Promise<ArrayBuffer> {
  return crypto.subtle.encrypt({ name: "AES-CBC", iv }, key, data);
}

export function aesDecrypt(key: CryptoKey, data: ArrayBuffer, iv: ArrayBuffer): Promise<ArrayBuffer> {
  return crypto.subtle.decrypt({ name: "AES-CBC", iv }, key, data);
}

export function aesDecryptStream(params: {
  key: CryptoKey;
  iv: ArrayBuffer;
  /** Size of the decrypted file, with padding removed */
  filesize: number;
  /** Inclusive byte range of the file that is available to be decrypted. Values must be divisible by 16 and may be larger than `filesize` due to padding */
  rangeAvailable?: Range;
  /** Inclusive byte range of the file to be returned. Must be within `rangeAvailable` */
  rangeRequested?: Range;
}): TransformStream<Uint8Array, Uint8Array> {
  return new TransformStream<Uint8Array, Uint8Array>(new AesDecryptTransformer(params));
}

export class AesDecryptTransformer implements Transformer<Uint8Array, Uint8Array> {
  key: CryptoKey;
  /** Size of the decrypted file, with padding removed */
  filesize: number;
  /** Inclusive byte range of the file that is available to be decrypted. Values must be divisible by 16 and may be larger than `filesize` due to padding */
  rangeAvailable?: Range;
  /** Inclusive byte range of the file to be returned. Must be within `rangeAvailable` */
  rangeRequested?: Range;

  iv: Uint8Array;
  pos: number;
  rest = new Uint8Array();
  /** Size of the encrypted file, including padding */
  encryptedFilesize: number;

  constructor(params: {
    key: CryptoKey;
    iv: ArrayBuffer;
    /** Size of the decrypted file, with padding removed */
    filesize: number;
    /** Inclusive byte range of the file that is available to be decrypted. Values must be divisible by 16 and may be larger than `filesize` due to padding */
    rangeAvailable?: Range;
    /** Inclusive byte range of the file to be returned. Must be within `rangeAvailable` */
    rangeRequested?: Range;
  }) {
    this.key = params.key;
    this.filesize = params.filesize;
    this.rangeAvailable = params.rangeAvailable;
    this.rangeRequested = params.rangeRequested;
    this.iv = new Uint8Array(params.iv);
    this.pos = params.rangeAvailable?.start ?? 0;
    this.encryptedFilesize = params.filesize + (16 - (params.filesize % 16));
    // console.log("decryptStream: encryptedFilesize", this.encryptedFilesize, "params", params);
  }

  async transform(chunk: Uint8Array, controller: TransformStreamDefaultController<Uint8Array>) {
    try {
      // first block is IV
      if ((this.rangeRequested?.start ?? 0) > 15 && this.pos < (this.rangeAvailable?.start ?? 0) + 16) {
        // TODO: fix
        // console.log("decryptStream: first block is IV");
        this.iv.set(chunk.subarray(0, 16), this.pos - (this.rangeAvailable?.start ?? 0));
        chunk = chunk.subarray(16);
        this.pos += 16;
      }

      /** Length of all encrypted data that is currently available */
      const encryptedLen = this.rest.length + chunk.length;
      /** Length of the data to be decrypted. Rest will be stored in `buffer` */
      const lenToDecrypt = encryptedLen - (encryptedLen % 16);
      this.pos += lenToDecrypt;
      const chunkIncludesPadding = this.pos >= this.encryptedFilesize;
      // console.log("decryptStream: encryptedLen", encryptedLen, "lenToDecrypt", lenToDecrypt, "pos", this.pos, "includes padding", chunkIncludesPadding, "iv", this.iv, "rest", this.rest); // prettier-ignore
      const chunkToDecrypt = new Uint8Array(lenToDecrypt + (chunkIncludesPadding ? 0 : 16));
      chunkToDecrypt.set(this.rest);
      /** Index of the first byte in `chunk` that will be moved to `buffer` instead of being decrypted immediately */
      const end = lenToDecrypt - this.rest.length;
      chunkToDecrypt.set(chunk.subarray(0, end), this.rest.length);
      this.rest = chunk.subarray(end);
      if (lenToDecrypt < 16) return;
      // The crypto API assumes the input to be padded, which is not the case in our stream -> we do the padding
      if (!chunkIncludesPadding) {
        const iv = chunkToDecrypt.subarray(-32, -16);
        const padding = await aesEncrypt(
          this.key,
          new Uint8Array(16).map(() => 16),
          iv
        );
        chunkToDecrypt.set(new Uint8Array(padding).subarray(0, 16), lenToDecrypt);
      }

      const sliceStart = Math.max(0, (this.rangeRequested?.start ?? 0) - this.pos + lenToDecrypt);
      const sliceEnd = (this.rangeRequested?.end ?? this.filesize - 1) + 1 - Math.min(this.pos, this.filesize);
      // decrypt
      const decrypted = new Uint8Array(await aesDecrypt(this.key, chunkToDecrypt, this.iv)).subarray(
        sliceStart,
        sliceEnd < 0 ? sliceEnd : undefined
      );
      this.iv = chunk.subarray(end - 16, end);
      controller.enqueue(decrypted);
    } catch (e) {
      console.error("decryptStream: error", e);
      controller.error(e);
    }
  }
}

export function generateAesKey(): Promise<CryptoKey> {
  return crypto.subtle.generateKey({ name: "AES-CBC", length: 256 }, true, ["decrypt", "encrypt"]);
}

// RSA

export function generateRsaKeyPair(): Promise<CryptoKeyPair> {
  return crypto.subtle.generateKey(
    {
      name: "RSA-OAEP",
      modulusLength: 4096,
      publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
      hash: "SHA-512",
    },
    true,
    ["decrypt", "encrypt"]
  );
}

export function rsaEncrypt(key: CryptoKey, data: ArrayBuffer): Promise<ArrayBuffer> {
  return crypto.subtle.encrypt({ name: "RSA-OAEP" }, key, data);
}

export function rsaDecrypt(key: CryptoKey, data: ArrayBuffer): Promise<ArrayBuffer> {
  return crypto.subtle.decrypt({ name: "RSA-OAEP" }, key, data);
}

// keys

export function exportRawKey(key: CryptoKey): Promise<ArrayBuffer> {
  return crypto.subtle.exportKey("raw", key);
}

export function importRawKey(key: ArrayBuffer): Promise<CryptoKey> {
  return crypto.subtle.importKey("raw", key, "AES-CBC", true, ["decrypt", "encrypt"]);
}

export function importPubkey(key: ArrayBuffer): Promise<CryptoKey> {
  return crypto.subtle.importKey("spki", key, { name: "RSA-OAEP", hash: "SHA-512" }, false, ["encrypt"]);
}

export function importPrivkey(key: ArrayBuffer): Promise<CryptoKey> {
  return crypto.subtle.importKey("pkcs8", key, { name: "RSA-OAEP", hash: "SHA-512" }, false, ["decrypt"]);
}

// base64

export function base64Encode(data: ArrayBuffer): string {
  return Buffer.from(data).toString("base64");
}
export function base64Decode(s: string): ArrayBuffer {
  return Buffer.from(s, "base64");
}

export function base64ToUrl(s: string): string {
  return s.replaceAll("=", "").replaceAll("+", "-").replaceAll("/", "_");
}

export function base64FromUrl(s: string): string {
  return s
    .replaceAll("-", "+")
    .replaceAll("_", "/")
    .padEnd(Math.ceil(s.length / 3) * 3, "=");
}

// base64 UUID

export function uuidToBase64(uuid: string): string | null {
  try {
    return base64ToUrl(Buffer.from(uuid.replaceAll("-", ""), "hex").toString("base64"));
  } catch {
    return null;
  }
}

export function uuidFromBase64(base64?: string): string | undefined {
  if (base64 === undefined) return;
  try {
    const h = Buffer.from(base64FromUrl(base64), "base64").toString("hex");
    if (h.length !== 32) return undefined;
    const r = `${h.substring(0, 8)}-${h.substring(8, 12)}-${h.substring(12, 16)}-${h.substring(16, 20)}-${h.substring(
      20,
      32
    )}`;
    return r.length === 36 ? r : undefined;
  } catch (e) {
    console.error("Error while parsing uuid", e);
  }
}

// UUID

export function randomUuid(): string {
  if (process.env.EXPO_PUBLIC_PPRO_EXT ?? "" in ["true", "1"]) {
    return uuidv4();
  } else {
    return Crypto.randomUUID();
  }
}

// comment relations

export async function getRelation(id: string, key: CryptoKey, n: number): Promise<ArrayBuffer> {
  const i = Buffer.from(id, "utf-8");
  const ek = await exportRawKey(key);
  const a = new Uint8Array(i.length + ek.byteLength + 4);
  a.set(i, 0);
  a.set(new Uint8Array(ek), i.length);
  a.set(new Uint8Array(new Int32Array([n]).buffer), i.length + ek.byteLength);
  return crypto.subtle.digest("SHA-512", a);
}

// ciphertext

/** Decrypt an AES-encrypted ciphertext and return a string. */
export async function decryptCiphertextString(key: CryptoKey, ciphertext: string): Promise<string> {
  return Buffer.from(await aesDecrypt(key, base64Decode(ciphertext), new ArrayBuffer(16))).toString("utf-8");
}

/** Decrypt an AES-encrypted ciphertext and return the parsed JSON object. */
export async function decryptCiphertext<T>(key: CryptoKey, ciphertext: string): Promise<T> {
  return JSON.parse(await decryptCiphertextString(key, ciphertext));
}

/** returns a base64 encoded string of the AES-encrypted ciphertext. */
export async function encryptCiphertextString(key: CryptoKey, ciphertext: string): Promise<string> {
  return base64Encode(await aesEncrypt(key, Buffer.from(ciphertext, "utf-8"), new ArrayBuffer(16)));
}

/** returns a base64 encoded string of the AES-encrypted JSON encoded ciphertext. */
export async function encryptCiphertext(key: CryptoKey, ciphertext: any): Promise<string> {
  return encryptCiphertextString(key, JSON.stringify(ciphertext));
}

/** Import an base64 encoded and AES-encrypted AES key as a `CryptoKey` */
export async function importAesEncryptedAesKey(decryptionKey: CryptoKey, encryptedKey: string) {
  return await importRawKey(await aesDecrypt(decryptionKey, base64Decode(encryptedKey), new ArrayBuffer(16)));
}

// Buffer
global.Buffer = global.Buffer || require("buffer").Buffer;
