import { FluentBundle, FluentResource } from "@fluent/bundle";
import { LocalizationProvider, ReactLocalization } from "@fluent/react";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { QueryClientProvider } from "react-query";
// @ts-ignore
import Core from "./Core";
import "./Polyfills";
import { queryClient, store } from "./model/Store";
import { useMaybePromise } from "./model/hooks/useMaybePromise";
import { register as registerServiceWorker } from "./service-worker/serviceWorkerRegistration";

export default function App() {
  console.log("store", store);
  const bundles = useMaybePromise(window.vidreLocalization);
  const l10n = new ReactLocalization(
    (bundles ?? []).map(({ lang, data }) => {
      const bundle = new FluentBundle(lang);
      const resource = new FluentResource(data);
      bundle.addResource(resource);
      return bundle;
    })
  );
  return (
    <LocalizationProvider l10n={l10n}>
      <QueryClientProvider client={queryClient}>
        <SafeAreaProvider>
          <GestureHandlerRootView style={{ flex: 1 }}>
            <Core />
          </GestureHandlerRootView>
        </SafeAreaProvider>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}

declare global {
  interface Window {
    vidreLocalization: Promise<{ lang: string; data: string }[]>;
  }
}

registerServiceWorker();
