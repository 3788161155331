import { PropsWithChildren, useCallback } from "react";
import { ContextMenuAction } from "./ContextMenuAction";
import { useContextMenu } from "./useContextMenu";

export default function ContextMenu(
  props: PropsWithChildren<{ actions: ContextMenuAction[]; dropdownMenuMode?: boolean }>
) {
  const { children, actions, dropdownMenuMode } = props;
  const showMenu = useContextMenu();
  const onContextMenu = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      e.stopPropagation();
      e.preventDefault();
      showMenu({ x: e.pageX, y: e.pageY }, actions);
    },
    [actions]
  );
  return (
    <div
      onClick={dropdownMenuMode ? onContextMenu : undefined}
      onContextMenu={onContextMenu}
      style={{ cursor: dropdownMenuMode ? "pointer" : undefined }}
    >
      {children}
    </div>
  );
}
