import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { TouchableOpacity, View } from "react-native";
import { VideoStoreContext } from "../../../../model/ui/VideoStore";
import { useColors } from "../../../Colors";
import styles from "../../../Styles";
import Icon from "../../../components/icon/Icon";
import CurrentTimecode from "./CurrentTimecode";
import VideoSlider from "./VideoSlider";
import VolumeControlWeb from "./VolumeControlWeb";

const VideoControls = observer(() => {
  const _vsStore = useContext(VideoStoreContext);
  const colors = useColors().video.controls;
  return (
    <View style={{ backgroundColor: colors.background }}>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        {/* timecode */}
        <View style={[styles.absoluteFill, styles.container]}>
          <CurrentTimecode color={colors.text} />
        </View>
        {/* playpause */}
        <TouchableOpacity style={{ padding: 4 }} onPress={_vsStore.playpause}>
          <Icon name={_vsStore.shouldPlay ? "pause" : "play"} size={30} color={colors.text} />
        </TouchableOpacity>
        {/* volume */}
        <VolumeControlWeb />
        {/* spacer */}
        <View style={{ flexGrow: 1 }} />
        {/* fullscreen */}
        <TouchableOpacity style={{ padding: 4 }} onPress={_vsStore.toggleFullscreen}>
          <Icon name="fullscreen" size={30} color={colors.text} />
        </TouchableOpacity>
      </View>
      <VideoSlider
        value={_vsStore.positionMillis ?? 0}
        maxValue={Math.max(_vsStore.durationMillis ?? 1, _vsStore.positionMillis ?? 0)}
        setValue={_vsStore.setPosition}
        buffered={_vsStore.bufferedMillis ?? 0}
        style={{ width: "100%", marginTop: -10 }}
        barsStyle={{ paddingVertical: 10 }}
        playedStyle={{ height: 2, backgroundColor: colors.accent }}
        bufferedStyle={{ height: 2, backgroundColor: colors.buffered }}
        backgroundStyle={{ height: 2, backgroundColor: colors.unbuffered }}
      />
    </View>
  );
});

export default VideoControls;
