import MaterialIcons from "@expo/vector-icons/MaterialIcons";

export default function Checkbox(props: {
  value: boolean;
  setValue: (v: boolean) => void;
  colorChecked: string;
  colorUnchecked: string;
  size?: number;
}) {
  const { value, setValue, colorChecked, colorUnchecked, size } = props;
  return (
    <MaterialIcons
      name={value ? "check-box" : "check-box-outline-blank"}
      color={value ? colorChecked : colorUnchecked}
      onPress={() => setValue(!value)}
      size={size}
      style={{ paddingTop: 0.5 }}
    />
  );
}
