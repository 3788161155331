import { VFile } from "../file/VFile";
import { getS3, S3EndpointInfo } from "../S3";
import { unsafeStore } from "../Store";
import { downloadFileVersion } from "./downloadFileVersion";
import Version, { VersionCiphertextJson, VersionJson, VersionParams } from "./Version";
import { VersionState } from "./VersionState";

export default abstract class FileVersion extends Version {
  originalMimeType: string;
  size: number;
  state: VersionState;

  get ciphertext(): FileVersionCiphertextJson {
    return {
      ...super.ciphertext,
      original_mime_type: this.originalMimeType,
    };
  }

  constructor(params: {
    id: string;
    file: VFile;
    name: string;
    originalMimeType: string;
    size: number;
    encKey: CryptoKey;
    state: VersionState;
    createdAt: Date;
  }) {
    const { id, file, name, encKey, createdAt, originalMimeType, size, state } = params;
    super({ id, file, name, encKey, createdAt });
    this.originalMimeType = originalMimeType;
    this.size = size;
    this.state = state;
    let v = unsafeStore.versions.get(id);
    if (v) console.warn("constructing new FileVersion with id that already exists", this);
  }

  getS3Path = (file: string): string => `/vidre/versions/${this.id}/${file}`;

  async getS3(file: string): Promise<{ fetch: Response; endpoint: S3EndpointInfo }> {
    return getS3(this.getS3Path(file));
  }

  download = async (): Promise<void> => downloadFileVersion(this);
}

export const createVersionCiphertext = (file: File): FileVersionCiphertextJson => ({
  name: file.name,
  original_mime_type: file.type,
});

export type FileVersionParams = VersionParams & { ciphertext: FileVersionCiphertextJson };

export type FileVersionJson = VersionJson & { size: number };

export type FileVersionCiphertextJson = VersionCiphertextJson & {
  original_mime_type: string;
};
