import { useEffect, useState } from "react";
import { Text } from "react-native";

export default function BlinkingDots() {
  const [numberOfDots, setNumberOfDots] = useState(0);
  useEffect(() => {
    const i = setInterval(() => setNumberOfDots(numberOfDots > 2 ? 0 : numberOfDots + 1), 500);
    return () => clearInterval(i);
  });

  return (
    <>
      <Text style={{ opacity: numberOfDots > 0 ? 1 : 0 }}>.</Text>
      <Text style={{ opacity: numberOfDots > 1 ? 1 : 0 }}>.</Text>
      <Text style={{ opacity: numberOfDots > 2 ? 1 : 0 }}>.</Text>
    </>
  );
}
