import { useMemo } from "react";
import { Platform, useColorScheme } from "react-native";
import seedrandom from "seedrandom";
import { User } from "../model/user/User";

interface ComponentColors {
  background: string;
  text: string;
  accent: string;
}

interface Colors {
  base: any;
  transparent: string;
  black: string;
  background: string;
  text: string;
  accent: string;

  // context menu
  contextMenu: ComponentColors & {
    destructive: string;
    disabled: string;
    border: string;
    backgroundHover: string;
  };

  // login & auth
  loginButton: ComponentColors;
  loginInput: ComponentColors & { border: string; placeholder: string };
  authScreen: ComponentColors & {
    switchView: string;
    error: string;
  };
  authModal: ComponentColors & {
    placeholder: string;
    switchView: string;
    error: string;
    inputBackground: string;
    border: string;
  };
  passwordBars: [string, string, string, string, string];

  // error & load screens
  errorScreen: ComponentColors;
  loadScreen: ComponentColors;
  saveStatusIndicator: {
    unsaved: string;
    saving: string;
    ok: string;
    failed: string;
    underlay: string;
  };

  // navbar
  navBar: ComponentColors & {
    icon: string;
    textSharedBy: string;
    border: string;
  };

  // modal
  modal: ComponentColors & {
    input: ComponentColors;
    share: {
      icon: string;
      divider: string;
      suggestions: ComponentColors & { underlay: string };
      item: ComponentColors & { link: string; circle: ComponentColors };
    };
    permissions: ComponentColors & { divider: string; checked: string; unchecked: string; icon: string };
    shadow: string;
  };
  modalIos: ComponentColors & { border: string };
  shareModalIos: ComponentColors & { separator: string };

  // upload & FileCard
  uploadManager: ComponentColors & {
    border: string;
    hover: string;
    barsBackground: string;
  };
  uploadButton: ComponentColors & { border: string };
  versionState: {
    fileUploadQueue: string;
    jobUpload: string;
    jobTranscode: string;
    jobDownload: string;
    fileUpload: string;
    done: string;
  };
  fileCard: ComponentColors & {
    imageBackground: string;
    dndMessageShadow: string;
    transparent: string;
    version: { background: string };
  };
  filesScreen: ComponentColors & { hover: string };

  // version selector
  versionBadge: ComponentColors;
  versionSelectorMenu: ComponentColors & {
    hover: string;
    textDummy: string;
    border: string;
  };

  // preview screen
  previewScreen: ComponentColors;
  imageSelector: ComponentColors & { previewBackground: string; number: string; numberBorder: string };
  image: ComponentColors;
  timeline: ComponentColors;
  video: {
    background: string;
    controls: ComponentColors & {
      buffered: string;
      unbuffered: string;
      volumeBackground: string;
      disabled: string;
    };
  };

  // comments
  comments: ComponentColors & {
    border: string;
    separator: ComponentColors & { icon: string };
  };
  commentsSeparatorMobile: ComponentColors & { border: string; icon: string };
  comment: ComponentColors & {
    highlighted: string;
    editedText: string;
    deletedText: string;
    editBackground: string;
    icon: string;
    replyBar: string;
    timecode: ComponentColors & {};
  };
  newCommentDesktop: ComponentColors & {
    enabled: string;
    placeholder: string;
    disabled: string;
    replyTo: ComponentColors & { cancel: string };
  };
  newCommentMobile: ComponentColors & {
    border: string;
    placeholder: string;
    send: string;
    replyTo: ComponentColors & { cancel: string };
  };
  commentContextToggle: ComponentColors & { disabled: string };

  // notifications
  notification: ComponentColors;
  notificationsScreen: ComponentColors;

  // menu
  menu: ComponentColors & {
    section: ComponentColors;
    divider: string;
    item: ComponentColors & { secondary: string; icon: string; reorder: string; chevron: string };
    dropdown: ComponentColors & {
      item: ComponentColors;
    };
  };
  settingsScreen: ComponentColors & { logout: string; divider: string; descriptionText: string };

  // buttons & MISC
  scaleButton: { active: string; disabled: string };
  fab: ComponentColors;

  userCircle: ComponentColors & { border: string };
  shareListItem: ComponentColors;
}

const darkColors = {
  accent1: "rgb(10, 140, 255)",
  accent2: "rgb(10, 125, 200)",
  accent3: "rgb(55, 161, 255)",

  foreground1: "#fff",
  foreground2: "#aaa",
  foreground3: "#888",
  foreground4: "#666",

  disabled: "#555",

  background0: "rgb(12, 12, 12)",
  background1: "rgb(18, 18, 18)",
  background2: "rgb(24, 24, 24)",
  background3: "rgb(32, 32, 32)",
  background4: "rgb(41, 41, 41)",
  background5: "rgb(54, 54, 54)",
  background6: "rgb(64, 64, 64)",

  error1: "#ff2200",
  error2: "#ff5500",
  warn1: "#ff2200",
  ok1: "rgb(40, 255, 100)",

  teal: "#0ff",
  blue: "#0af",
  purple: "#a0f",
  green: "#0f0",
  orange: "#f70",
  yellow: "#af0",
  black: "#000",
  white: "#fff",
  grey: "rgb(127, 127, 127)",

  transparent: "rgba(0, 0, 0, 0)",
};

const lightColors = {
  accent1: "rgb(10, 140, 255)",
  accent2: "rgb(10, 125, 200)",
  accent3: "rgb(0, 91, 140)",

  foreground1: "#000",
  foreground2: "#555",
  foreground3: "#888",
  foreground4: "#aaa",

  disabled: "#555",

  background0: "rgb(255, 255, 255)",
  background1: "rgb(248, 248, 248)",
  background2: "rgb(239, 239, 239)",
  background3: "rgb(231, 231, 231)",
  background4: "rgb(219, 219, 219)",
  background5: "rgb(213, 213, 213)",
  background6: "rgb(191, 191, 191)",

  error1: "#ff2200",
  error2: "#ff5500",
  warn1: "#ff2200",
  ok1: "rgb(40, 255, 100)",

  teal: "#0ff",
  blue: "#0af",
  purple: "#a0f",
  green: "#0f0",
  orange: "#f70",
  yellow: "#af0",
  black: "#000",
  white: "#fff",
  grey: "rgb(127, 127, 127)",

  transparent: "rgba(0, 0, 0, 0)",
};

export function useColors(): Colors {
  const colorscheme = useColorScheme();

  const base = colorscheme === "light" ? lightColors : darkColors;

  function component(params?: { background?: string; text?: string; accent?: string }): ComponentColors {
    const { background, text, accent } = params ?? {};

    return {
      ...params,
      background: background ?? base.background0,
      text: text ?? base.foreground1,
      accent: accent ?? base.accent1,
    };
  }

  return useMemo(
    () => ({
      base,
      transparent: base.transparent,
      black: base.black,
      background: base.background0,
      text: base.foreground1,
      accent: base.accent1,

      // context menu
      contextMenu: {
        ...component({ background: colorscheme === "light" ? base.background1 : base.background3 }),
        destructive: base.warn1,
        disabled: base.disabled,
        border: base.background5,
        backgroundHover: base.background4,
      },

      // login & auth
      loginButton: component({ background: base.accent2, text: base.white }),
      loginInput: {
        ...component({ background: base.background1 }),
        border: colorscheme === "light" ? base.background3 : base.background6,
        placeholder: base.foreground3,
      },
      authScreen: {
        ...component(),
        switchView: base.accent1,
        error: base.error1,
      },
      authModal: {
        ...component(),
        placeholder: base.foreground4,
        switchView: base.accent1,
        error: base.error1,
        inputBackground: base.background1,
        border: base.background4,
      },
      passwordBars: [base.error1, base.error2, base.orange, base.yellow, base.ok1],

      // error & load screens
      errorScreen: component({ accent: base.error1 }),
      loadScreen: component({ accent: base.accent3 }),
      saveStatusIndicator: {
        unsaved: base.foreground2,
        saving: base.foreground2,
        ok: base.ok1,
        failed: base.error1,
        underlay: base.background5,
      },

      // navbar
      navBar: {
        icon: Platform.OS === "ios" ? base.accent1 : base.foreground2,
        textSharedBy: base.foreground2,
        border: base.background6,
        ...component(
          colorscheme === "light"
            ? { text: base.foreground2, background: base.background0, accent: base.accent1 }
            : { text: base.foreground1, background: base.background2, accent: base.accent3 }
        ),
      },

      // modal
      modal: {
        ...component({ background: base.background3 }),
        input: component({ background: base.background1 }),
        share: {
          icon: base.foreground2,
          divider: base.background4,
          suggestions: {
            ...component({ background: base.background3 }),
            underlay: base.background4,
          },
          item: {
            ...component({ text: base.foreground2 }),
            link: base.foreground3,
            circle: { ...component(), text: base.foreground2, background: base.background5 },
          },
        },
        permissions: {
          ...component(),
          icon: base.foreground2,
          divider: base.background4,
          checked: base.foreground2,
          unchecked: base.foreground2,
        },
        shadow: "#0008",
      },
      modalIos: {
        ...component({ background: base.background2 }),
        border: base.background4,
      },
      shareModalIos: {
        separator: base.background1,
        ...component({ background: base.background4 }),
      },
      linkListItem: { link: base.foreground3, ...component() },

      // upload & FileCard
      uploadManager: {
        ...component({ background: base.background3 }),
        hover: base.background4,
        border: base.background5,
        barsBackground: base.background1,
      },
      uploadButton: {
        ...component({ background: base.background4 }),
        border: base.background6,
      },
      versionState: {
        fileUploadQueue: base.yellow,
        fileUpload: base.orange,
        jobDownload: base.purple,
        jobTranscode: base.blue,
        jobUpload: base.teal,
        done: base.green,
      },
      fileCard: {
        imageBackground: base.black,
        dndMessageShadow: base.grey,
        transparent: base.transparent,
        version: { background: base.background0 },
        ...component({ text: base.foreground2, background: base.background2 }),
      },
      filesScreen: { ...component(), hover: base.accent3 },

      // version selector
      versionBadge: component({ background: base.background4 }),
      versionSelectorMenu: {
        ...component({ background: base.background3 }),
        hover: base.background4,
        textDummy: base.foreground3,
        border: base.background5,
      },

      // preview screen
      previewScreen: component({ background: base.background1, text: base.foreground3 }),
      imageSelector: {
        ...component({ background: base.background2 }),
        previewBackground: base.background1,
        number: "#0004",
        numberBorder: "#fff6",
      },
      image: component({ background: colorscheme === "light" ? base.white : base.black }),
      timeline: component({ background: base.background1 }),
      video: {
        background: base.black,
        controls: {
          ...component(),
          buffered: base.background1,
          unbuffered: base.background1,
          volumeBackground: "#fff3",
          disabled: base.disabled,
        },
      },

      // comments
      comments: {
        ...component({ background: base.background1 }),
        border: base.background5,
        separator: {
          ...component({ background: base.background3 }),
          icon: base.foreground2,
        },
      },
      commentsSeparatorMobile: {
        border: base.background5,
        icon: base.foreground4,
        ...component({ background: base.background1, text: base.foreground3 }),
      },
      comment: {
        ...component({ background: base.background2 }),
        highlighted: base.background3,
        editedText: base.foreground2,
        deletedText: base.foreground2,
        editBackground: base.background0,
        icon: base.foreground3,
        replyBar: base.background5,
        timecode: {
          ...component({ background: base.background4 }),
        },
      },
      newCommentDesktop: {
        enabled: base.accent2,
        disabled: base.disabled,
        placeholder: base.foreground4,
        ...component({ background: base.background2 }),
        replyTo: {
          cancel: base.foreground4,
          ...component({ text: base.foreground3, background: base.background1 }),
        },
      },
      newCommentMobile: {
        border: base.background2,
        placeholder: base.foreground4,
        send: base.white,
        ...component({ background: base.background1 }),
        replyTo: {
          cancel: base.foreground4,
          ...component({ text: base.foreground3, background: base.background2 }),
        },
      },
      commentContextToggle: {
        disabled: base.disabled,
        ...component({ accent: base.accent1, text: base.foreground3 }),
      },

      // notifications
      notification: component({ background: base.background2 }),
      notificationsScreen: component(),

      // menu
      menu: {
        ...component(),
        section: component({ text: base.foreground2 }),
        divider: base.background4,
        item: {
          ...component(),
          secondary: base.foreground2,
          icon: base.foreground3,
          reorder: base.foreground4,
          chevron: base.foreground3,
        },
        dropdown: {
          ...component(),
          item: component({ text: base.foreground2, background: base.background2 }),
        },
      },
      settingsScreen: {
        ...component({ background: base.background3 }),
        logout: base.warn1,
        divider: base.background4,
        descriptionText: base.foreground4,
      },

      // buttons & MISC
      scaleButton: { active: base.accent1, disabled: base.disabled },
      fab: component({ background: base.accent1, text: base.white }),

      userCircle: { border: base.foreground4, ...component({ text: base.white, background: base.background5 }) },
      shareListItem: {
        ...component(),
        icon: base.foreground2,
        divider: base.background4,
        checked: base.foreground2,
        unchecked: base.foreground2,
      },
    }),

    [colorscheme]
  );
}

export function randomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

// https://stackoverflow.com/questions/2353211/hsl-to-rgb-color-conversion
/**
 * Converts an HSL color value to RGB. Conversion formula
 * adapted from http://en.wikipedia.org/wiki/HSL_color_space.
 * Assumes h, s, and l are contained in the set [0, 1] and
 * returns r, g, and b in the set [0, 255].
 *
 * @param   {number}  h       The hue
 * @param   {number}  s       The saturation
 * @param   {number}  l       The lightness
 * @return  {[number, number, number]}           The RGB representation
 */
export function hslToRgb(h: number, s: number, l: number): [number, number, number] {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hueToRgb(p, q, h + 1 / 3);
    g = hueToRgb(p, q, h);
    b = hueToRgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function hueToRgb(p: number, q: number, t: number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1 / 6) return p + (q - p) * 6 * t;
  if (t < 1 / 2) return q;
  if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
  return p;
}

export function rgbColor(r: number, g: number, b: number) {
  return `rgb(${r}, ${g}, ${b})`;
}

export function useRandomBackgroundColor(seed?: string) {
  const colorscheme = useColorScheme();

  return useMemo(() => {
    const random = seedrandom(seed);
    const l = colorscheme === "light" ? 0.8 : 0.2;
    return rgbColor(...hslToRgb(random(), 0.5, l));
  }, [colorscheme, seed]);
}

export function useRandomAccentColor(seed?: string) {
  const colorscheme = useColorScheme();

  return useMemo(() => {
    const random = seedrandom(seed);
    const l = colorscheme === "light" ? 0.2 : 0.8;
    return rgbColor(...hslToRgb(random(), 0.5, l));
  }, [colorscheme, seed]);
}

export function useUserColor(user: User) {
  return useMemo(() => {
    const random = seedrandom(user.id);
    return rgbColor(...hslToRgb(random(), 0.8, 0.5));
  }, [user]);
}
