import { makeAutoObservable, observable, ObservableMap } from "mobx";
import { Store, unsafeStore } from "../Store";
import { User } from "../user/User";
import Version from "../version/Version";
import { Commentable } from "./Commentable";
import { sortComments } from "./sortComments";

export default class Comment implements Commentable {
  id: string;
  type: CommentType;
  encKey: CryptoKey;
  versions: ObservableMap<number, CommentVersion>;
  replyTo?: Comment;
  relationNo: number;
  version: Version;

  deletedAt?: Date;

  isHighlighted = false;

  get key() {
    return this.id;
  }

  /** returns the latest version that was not deleted */
  get latestVersion(): ExistingCommentVersion | undefined {
    return [...this.versions.values()].findLast(isExistingCommentVersion);
  }
  get oldestVersion(): CommentVersion {
    return [...this.versions.values()].sort((a, b) => b.createdAt.getMilliseconds() - a.createdAt.getMilliseconds())[0];
  }
  get isEdited() {
    return this.versions.size > 1;
  }
  get isDeleted() {
    return this.deletedAt !== undefined;
  }
  get canEdit() {
    return this.oldestVersion?.author === unsafeStore.me;
  }
  get canDelete() {
    return this.oldestVersion?.author === unsafeStore.me;
  }

  nextRelation = 0;
  get maxExistingRelation() {
    return this.comments.size > 0
      ? [...this.comments.values()].map((v) => v.relationNo).reduce((a, b) => Math.max(a, b))
      : -1;
  }

  comments = observable.map<string, Comment>();

  wipComment: WipComment | null = null;
  setComment(t: string): WipComment {
    if (this.wipComment) this.wipComment.text = t;
    else
      this.wipComment = new WipComment({
        text: t,
        type: "io.vidre.text",
        startTimecode: this.latestVersion?.startTimecode,
        endTimecode: this.latestVersion?.endTimecode,
      });
    return this.wipComment;
  }

  commentsVisible = true;

  get sortedComments(): Comment[] {
    return sortComments([...this.comments.values()], unsafeStore.commentSortStrategy);
  }

  constructor(params: {
    id: string;
    type: CommentType;
    encKey: CryptoKey;
    relationNo: number;
    versions: Map<number, CommentVersion>;
    replyTo?: Comment;
    version: Version;
    deletedAt?: Date;
  }) {
    const { id, type, encKey, relationNo, versions, replyTo, version, deletedAt } = params;
    this.id = id;
    this.type = type;
    this.encKey = encKey;
    this.relationNo = relationNo;
    this.versions = observable.map(versions);
    this.replyTo = replyTo;
    this.version = version;
    this.deletedAt = deletedAt;
    makeAutoObservable(this);
  }
}

export type CommentVersion = ExistingCommentVersion | DeletedCommentVersion;

export type ExistingCommentVersion = {
  readonly text: string;
  readonly author: User;
  readonly startTimecode?: number;
  readonly endTimecode?: number;
  readonly createdAt: Date;
};
export type DeletedCommentVersion = {
  readonly author: User;
  readonly createdAt: Date;
  readonly deletedAt: Date;
};

export function isExistingCommentVersion(arg: CommentVersion): arg is ExistingCommentVersion {
  return !("deletedAt" in arg);
}

export type CommentVersionCiphertextJson = {
  text: string;
  startTimecode?: number;
  endTimecode?: number;
};

export type CommentJson = {
  id: string;
  type: string;
  relation: string;
  key: string;
  versions: CommentVersionJson[];
  deletedAt?: string;
};

export type CommentVersionJson = ExistingCommentVersionJson | DeletedCommentVersionJson;

export type ExistingCommentVersionJson = {
  ciphertext: string;
  author: string;
  createdAt: string;
  deletedAt?: null;
};

export type DeletedCommentVersionJson = {
  ciphertext?: null;
  author: string;
  createdAt: string;
  deletedAt: string;
};

/**
 * Ensure that a comment version has not been deleted
 */
export function isExistingCommentVersionJson(arg: CommentVersionJson): arg is ExistingCommentVersionJson {
  return !("deletedAt" in arg && arg.deletedAt !== null);
}

export class WipComment {
  text: string;
  startTimecode: number | null;
  endTimecode: number | null;
  type: CommentType;

  get canSend() {
    return this.text.trim().length > 0;
  }

  constructor(params: {
    text?: string;
    type: CommentType;
    startTimecode?: number | null;
    endTimecode?: number | null;
  }) {
    this.text = params?.text ?? "";
    this.type = params.type;
    this.startTimecode = params?.startTimecode ?? null;
    this.endTimecode = params?.endTimecode ?? null;
    makeAutoObservable(this);
  }
}

export type CommentType = "io.vidre.text" | "io.vidre.reaction";

export function isCommentType(v: any): v is CommentType {
  switch (v) {
    case "io.vidre.text":
    case "io.vidre.reaction":
      return true;
  }
  return false;
}

export function parseCommentType(value: string): CommentType {
  if (!isCommentType(value)) throw new Error(`invalid comment type: "${value}"`);
  return value;
}

export function commentRelData(
  _store: Store,
  relation: string
): {
  relationNo: number;
  version: Version;
  replyTo?: Comment;
} {
  const relD = _store.subscribedCommentRelations.get(relation);
  if (relD?.c instanceof Version) {
    return { relationNo: relD.n, version: relD.c };
  } else if (relD?.c instanceof Comment) {
    return { relationNo: relD.n, version: relD.c.version, replyTo: relD?.c };
  } else {
    throw new Error("Unknown commentable type:", { cause: [relD?.c, relD] });
  }
}
